import TitleMask from '@/components/Common/Layout/TitleMask';
import LoginForm from '@/components/Login/LoginForm';

function Login() {
  return (
    <TitleMask title="DRAFT NOTE Login">
      <LoginForm />
    </TitleMask>
  );
}

export default Login;
