const REGEX = {
  PASSWORD:
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,20}/,
};

const ERROR_MESSAGE = {
  ID: {
    REQUIRED: '이메일을 입력해주세요.',
    SEND: '인증코드를 발송했습니다.',
    CERTIFICATION: '이메일 인증을 진행해 주세요.',
    INVALIDATION: '이메일 입력 양식이 일치하지 않습니다.',
  },
  CODE: {
    VALIDATION: '인증코드가 확인되었습니다.',
    INVALIDATION:
      '인증코드가 일치하지 않습니다. 올바른 인증코드를 입력해주세요. ',
  },
  AUTH: {
    INCORRECT: '인증코드가 일치하지 않습니다. 다시 입력해 주세요.',
    INVALIDATION: '올바른 코드번호를 입력해 주세요.',
  },
  NICKNAME: {
    REQUIRED: '닉네임을 입력해 주세요.',
    NOT_UNIQUE: '중복된 닉네임 입니다. 다른 닉네임을 입력해주세요.',
    INVALIDATION: '올바른 닉네임을 입력해 주세요.',
  },
  PASSWORD: {
    REQUIRED: '비밀번호를 입력해 주세요.',
    CONFIRM: '비밀번호를 한번 더 입력해 주세요.',
    NOT_MATCH: '비밀번호가 일치하지 않습니다.',
    INVALIDATION: '비밀번호 입력 양식이 일치하지 않습니다.',
    SHOULD_REQUIRED: '변경할 경우 필수 입력 항목입니다.',
  },
  LOGIN: {
    NOT_FOUND: '일치하는 아이디가 없습니다. 확인 후 다시 입력해 주세요.',
    NO_MATCH:
      '아이디와 비밀번호가 일치하지 않습니다. 확인 후 다시 입력해 주세요.',
  },
  COMMUNITY: {
    TITLE: {
      MAX: '제목은 20자 이내로 입력해주세요.',
      REQUIRED: '제목을 입력해주세요.',
    },
    CONTENTS: {
      REQUIRED: '본문을 입력해주세요.',
    },
  },
  FAQ: {
    TITLE: {
      MAX: '제목은 255자 이내로 입력해주세요.',
      REQUIRED: '제목을 입력해주세요.',
    },
    CATEGORY: {
      MAX: '카테고리는 30자 이내로 입력해주세요.',
      REQUIRED: '카테고리를 입력해주세요.',
    },
    CONTENTS: {
      REQUIRED: '본문을 입력해주세요.',
    },
  },
  CS: {
    NAME: {
      MAX: '이름은 100자 이내로 입력해주세요.',
      REQUIRED: '이름을 입력해주세요.',
    },
    TITLE: {
      MAX: '제목은 100자 이내로 입력해주세요.',
      REQUIRED: '제목을 입력해주세요.',
    },
  },
  REQUIRED: '필수 입력 항목입니다.',
  MAX: '입력 가능한 글자수를 초과하였습니다.',
  NONE: '',
};

export { ERROR_MESSAGE, REGEX };
