import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button, { ButtonVariant, ButtonSize } from '@/components/Common/Button';
import TextField from '@/components/Common/TextField';
import Checkbox from '@/components/Common/Checkbox';
import {
  ErrorMessage,
  StyledForm,
  StyledFormContainer,
} from '@/components/Common/Form';
import {
  UtilWrapper,
  ButtonWrapper,
} from '@/components/Login/LoginForm/LoginForm.styled';
import { ERROR_MESSAGE } from '@/utils/validation';
import useLogin from '@/hooks/auth/useLogin';

function LoginForm() {
  const router = useRouter();
  const { mutate: loginMutate } = useLogin();
  const [email, setEmail] = useState();
  const [isRememberEmail, setIsRememberEmail] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<Record<string, string>>({
    email: ERROR_MESSAGE.NONE,
    password: ERROR_MESSAGE.NONE,
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  useEffect(() => {
    reset({ email });
  }, [reset, email]);

  useEffect(() => {
    setRememberedEmail();
  }, []);

  const setRememberedEmail = () => {
    if (localStorage.getItem('rememberEmail') !== null) {
      setEmail(localStorage.getItem('rememberEmail') as string);
      setIsRememberEmail(true);
    }
  };

  const onSubmit = (data: any) => {
    const { email, password } = data;

    if (isRememberEmail) {
      localStorage.setItem('rememberEmail', email);
    } else {
      localStorage.setItem('rememberEmail', '');
    }

    loginMutate(
      { email, password },
      {
        onError: () => {
          window.alert('로그인에 실패하였습니다.');
          /**
                     // TODO set server side error message
                     setErrorField({
            email: ERROR_MESSAGE.LOGIN.NO_MATCH,
            password: ERROR_MESSAGE.LOGIN.NO_MATCH,
          });
                     */
        },
      }
    );
  };

  const handleClick = () => {
    router.push('/register');
  };

  const handleRadioClick = () => {
    setIsRememberEmail(!isRememberEmail);
  };

  return (
    <StyledFormContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {Fields.map(field => (
          <div key={field.name}>
            <Controller
              control={control}
              name={field.name}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} {...field} />
              )}
            />
            {errors[field.name]?.message && (
              <ErrorMessage message={errors[field.name]?.message} />
            )}
          </div>
        ))}
        <div className="form-footer">
          <UtilWrapper>
            <div className="save-id-wrapper">
              <Checkbox
                checked={isRememberEmail}
                onChange={handleRadioClick}
                name="save-id"
                id="save-id"
                size={20}
              />
              <span>아이디 저장 |</span>
            </div>
            <div className="fine-pw-wrapper">
              <Link href="/find/password">비밀번호 찾기</Link>
            </div>
          </UtilWrapper>
          <ButtonWrapper>
            <Button
              label="로그인"
              className="full"
              size={ButtonSize.Big}
              type="submit"
            />
            <Button
              label="회원가입"
              variant={ButtonVariant.Mono}
              onClick={handleClick}
            />
          </ButtonWrapper>
        </div>
      </StyledForm>
    </StyledFormContainer>
  );
}

export default LoginForm;

const LoginSchema = yup.object({
  email: yup
    .string()
    .email(ERROR_MESSAGE.ID.INVALIDATION)
    .required(ERROR_MESSAGE.ID.REQUIRED),
  password: yup.string().required(ERROR_MESSAGE.PASSWORD.REQUIRED),
});

const Fields = [
  {
    label: '아이디',
    name: 'email',
    placeholder: '아이디를 입력해주세요',
    type: 'string',
  },
  {
    label: '비밀번호',
    name: 'password',
    placeholder: '비밀번호를 입력해주세요',
    type: 'password',
  },
];
