import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { login } from '@/api/auth';
import { setLoginToken } from '@/api';
import useUser from '@/hooks/auth/useUser';

function useLogin() {
  const router = useRouter();
  const userMutate = useUser();

  return useMutation(login, {
    onSuccess: data => {
      setLoginToken(data.access_token);
      userMutate.mutate();
      router.push('/');
    },
  });
}

export default useLogin;
