import styled from 'styled-components';

const Container = styled.div``;
const CheckboxLayout = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  appearance: none;
`;
const LabelEle = styled.label`
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    padding: 0 ${({ theme }) => theme.paddings.small};
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-family: ${({ theme }) => theme.fonts.NotoSansKr};
    color: ${({ theme }) => theme.palette.gray};
  }
`;

export { Container, CheckboxLayout, LabelEle };
