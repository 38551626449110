import Image from 'next/image';
import React from 'react';
import IcoChecked from '@/assets/icons/ico-checkbox-checked.svg';
import IcoUnChecked from '@/assets/icons/ico-checkbox-unchecked.svg';
import { Container, CheckboxLayout, LabelEle } from './Checkbox.styled';
import { CheckboxProps } from './Checkbox.types';

function Checkbox({
  id,
  name,
  checked,
  onChange,
  size,
  children,
}: CheckboxProps) {
  return (
    <Container>
      <CheckboxLayout
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <LabelEle htmlFor={id}>
        <Image
          src={checked ? IcoChecked : IcoUnChecked}
          width={size}
          height={size}
          alt="checked"
        />
        {children}
      </LabelEle>
    </Container>
  );
}
export default Checkbox;
