import styled from 'styled-components';

const UtilWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-family: ${({ theme }) => theme.fonts.NotoSansKr};
  color: ${({ theme }) => theme.palette.gray};

  .save-id-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 4px;
    }
  }

  .fine-pw-wrapper {
    display: flex;
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;

  button {
    margin: 8px 0;

    &.full {
      width: 100%;
    }
  }
`;

export { UtilWrapper, ButtonWrapper };
